import classNames from "classnames";
import { motion } from "framer-motion";
import { useFeatureStore } from "../store";
import steponeImage from "../Assets/step1.png";
import steptwoImage from "../Assets/step2.png";
import stepthreeImage from "../Assets/step3.png";
import stepfourImage from "../Assets/step4.png";

const FeatureCard = ({ gradient, children, id }) => {
  const inViewFeature = useFeatureStore((state) => state.inViewFeature);
  return (
    <div
      className={classNames(
        "absolute max-h-[350px] right-0 max-w-[350px] top-1/2 2 transform  -translate-y-1/2  transition-opacity flex flex-col items-center justify-center gap-2",
        inViewFeature === id
          ? "active-card opacity-100"
          : "pointer-events-none opacity-0"
      )}
    >
      <motion.p
        transition={{ duration: 0.2, ease: "linear" }}
        whileInView={{ opacity: 1 }}
        className="text-[#FFD401] md:text-4xl font-bold font-Rubik text-2xl"
      >
        How To Use
      </motion.p>
      {children}
    </div>
  );
};

export const stepOne = ({ id }) => {
  return (
    <FeatureCard id={id}>
      <img
        src={steponeImage}
        alt=""
        className="w-full h-full rounded-[20px]  object-cover"
      />
      <motion.a
        href="https://t.me/bartFund_bot"
        target="_blank"
        className="flex gap-2 text-white font-medium font-Poppins text-xs md:text-xl  flex-row items-center justify-center px-6 py-3 bg-[#202020] fill-white rounded-full"
        whileHover={{
          background: "white",
          color: "#202020",
          fill: "#202020",
        }}
        whileTap={{ scaleY: 0.9, scaleX: 0.9 }}
      >
        Launch Bart
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-robot"
          viewBox="0 0 16 16"
        >
          <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
          <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
        </svg>
      </motion.a>
    </FeatureCard>
  );
};

export const StepTwo = ({ id }) => {
  return (
    <FeatureCard id={id} gradient="from-[#f5fbff] to-[#addeff]">
      <img
        src={steptwoImage}
        alt=""
        className="w-full h-full rounded-[20px]  object-cover"
      />
    </FeatureCard>
  );
};

export const StepThree = ({ id }) => {
  return (
    <FeatureCard id={id} gradient="from-[#f5fff7] to-[#adf8ff]">
      <img
        src={stepthreeImage}
        alt=""
        className="w-full h-full rounded-[20px]  object-cover"
      />
    </FeatureCard>
  );
};
export const StepFour = ({ id }) => {
  return (
    <FeatureCard id={id} gradient="from-[#f5fff7] to-[#adf8ff]">
      <img
        src={stepfourImage}
        alt=""
        className="w-full h-full rounded-[20px]  object-cover"
      />
    </FeatureCard>
  );
};
