import { useInView } from "framer-motion";
import { useFeatureStore } from "../store";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const Title = ({ children, id, heading }) => {
  const ref = useRef(null);
  const documentRef = useRef(document);
  const isInView = useInView(ref, {
    margin: "-50% 0px -50% 0px",
    root: documentRef,
  });
  const setInViewFeature = useFeatureStore((state) => state.setInViewFeature);
  const inViewFeature = useFeatureStore((state) => state.inViewFeature);

  useEffect(() => {
    if (isInView) setInViewFeature(id);
    if (!isInView && inViewFeature === id) setInViewFeature(null);
  }, [isInView, id, setInViewFeature, inViewFeature]);

  return (
    <div className="py-16 feature-title flex flex-col gap-2 " ref={ref}>
      <h1
        className={classNames(
          "font-extrabold font-Poppins  text-lg text-start md:text-2xl flex flex-col md:flex-row  md:items-center gap-2",
          isInView ? "text-white/80" : "text-gray-300/25"
        )}
      >
        {heading}
        {isInView ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-arrow-down-right-circle "
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.854 5.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z"
            />
          </svg>
        ) : null}
      </h1>
      <p
        className={classNames(
          "font-Inter text-xs md:text-2xl transition-colors",
          isInView ? "text-[#FFD401]" : "text-gray-300/25"
        )}
      >
        {children}
      </p>
    </div>
  );
};

export default Title;
