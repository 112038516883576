import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { motion, useInView } from "framer-motion";
import heroPNG from "../Assets/home.png";
import aboutPNG from "../Assets/aboutPNG.png";
import roadmapImg from "../Assets/bartimg.png"
import HeroWebm from "../Assets/home.webm";
import logo from "../Assets/logo.png";
import homer from "../Assets/homer.png";
import Title from "../components/Title";
import {
  Availability,
  Colors,
  Music,
  SchedulingLinks,
  StepFour,
  StepThree,
  StepTwo,
  Team,
  Todo,
  stepOne,
} from "../components/card";
function Navbar() {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, translateY: -100, position: "absolute" }}
      animate={{
        position: isFixed ? "fixed" : "absolute",
        translateY: 0,
      }}
      exit={{
        opacity: 0,
        scaleY: -100,
      }}
      layout
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{
        delay: !isFixed ? "0.2" : "0",
        type: !isFixed ? "spring" : "",
        duration: "0.5",
      }}
      className={`navbar h-20 md:h-24 w-full absolute top-0  flex flex-row items-center justify-between px-4 md:px-8 z-10 ${isFixed ? "bg-[black] " : ""
        }`}
    >
      <a
        href="/"
        className="logo flex flex-row items-center justify-start gap-2 h-1/2"
      >
        <img src={logo} alt="" className="h-full" />
      </a>
      <div className="button 4 ">
        <motion.button
          className={`font-semibold font-inter  text-sm md:text-xl  rounded-full px-4 py-2 md:px-6 md:py-3 text-center flex flex-row items-center justify-center gap-2 ${isFixed
            ? "bg-white text-[#202020] fill-[#202020]"
            : "text-white bg-[#202020] fill-white"
            }`}
          whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
          whileTap={{ scaleY: 0.9, scaleX: 0.9 }}
        >
          Try Now
          <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.75 4.75H19C20.6569 4.75 22 6.09315 22 7.75V13.75C22 15.4069 20.6569 16.75 19 16.75H3C1.34315 16.75 0 15.4069 0 13.75V7.75C0 6.09315 1.34315 4.75 3 4.75H10.25V2.75C10.25 1.7835 11.0335 1 12 1C12.1381 1 12.25 0.888071 12.25 0.75C12.25 0.335786 12.5858 0 13 0C13.4142 0 13.75 0.335786 13.75 0.75C13.7337 1.70641 12.9565 2.4747 12 2.48C11.9301 2.47978 11.8633 2.50884 11.8158 2.56015C11.7683 2.61145 11.7444 2.6803 11.75 2.75V4.75ZM19 15.25C19.8284 15.25 20.5 14.5784 20.5 13.75V7.75C20.4837 6.93331 19.8169 6.27984 19 6.28H3C2.18315 6.27984 1.51634 6.93331 1.5 7.75V13.75C1.5 14.5784 2.17157 15.25 3 15.25H19Z"
            />
            <path d="M8 10.02H6.75V8.75C6.75 8.33579 6.41421 8 6 8C5.58579 8 5.25 8.33579 5.25 8.75V10H4C3.58579 10 3.25 10.3358 3.25 10.75C3.25 11.1642 3.58579 11.5 4 11.5H5.25V12.75C5.25 13.1642 5.58579 13.5 6 13.5C6.41421 13.5 6.75 13.1642 6.75 12.75V11.52H8C8.41421 11.52 8.75 11.1842 8.75 10.77C8.75 10.3558 8.41421 10.02 8 10.02Z" />
            <path d="M16 8.77C16 9.32228 15.5523 9.77 15 9.77C14.4477 9.77 14 9.32228 14 8.77C14 8.21772 14.4477 7.77 15 7.77C15.5523 7.77 16 8.21772 16 8.77Z" />
            <path d="M16 12.77C16 13.3223 15.5523 13.77 15 13.77C14.4477 13.77 14 13.3223 14 12.77C14 12.2177 14.4477 11.77 15 11.77C15.5523 11.77 16 12.2177 16 12.77Z" />
            <path d="M18 10.77C18 11.3223 17.5523 11.77 17 11.77C16.4477 11.77 16 11.3223 16 10.77C16 10.2177 16.4477 9.77 17 9.77C17.5523 9.77 18 10.2177 18 10.77Z" />
            <path d="M14 10.77C14 11.3223 13.5523 11.77 13 11.77C12.4477 11.77 12 11.3223 12 10.77C12 10.2177 12.4477 9.77 13 9.77C13.5523 9.77 14 10.2177 14 10.77Z" />
          </svg>
        </motion.button>
      </div>
    </motion.div>
  );
}

function Home() {
  const features = [
    {
      heading: "Launch the Bot and Start Interaction",
      title: `Click on the "Launch" button to open the bot, which will seamlessly redirect you to the Telegram app. Upon redirection, locate the bot's profile and click on the "Start" button to initiate interaction. This action will set the stage for your seamless experience with the bot on Telegram.`,
      id: "Step-1",
      card: stepOne,
    },
    {
      heading: "Import Private Key and Open the App",
      title: `After clicking the "Start" button, you'll receive your private key and wallet address. Import this private key into your preferred wallet, such as MetaMask. Once your wallet is successfully stored, proceed by clicking on the "Bart" button in the Telegram menu or simply text "Bart" to open the app. This step ensures your wallet is seamlessly integrated into the bot's functionality`,
      id: "Step-2",
      card: StepTwo,
    },
    {
      heading: "Fund Your Wallet and Explore Pools",
      title:
        "Upon opening the app, fund your wallet with the desired amount. Explore available prediction pools by checking their details. Once you've identified a specific pool you want to predict for, click on the pool option button associated with that particular pool. This step allows you to actively engage in predicting outcomes for the chosen pool.",
      id: "Step-3",
      card: StepThree,
    },
    {
      heading: "Check Transaction Details and Await Rewards",
      title:
        "After making predictions, you'll receive a transaction Hash. Utilize this Hash to inspect your transaction details and monitor the pool's status. Once the pool concludes, and if your prediction proves correct, expect your rewards. Stay tuned for the pool's conclusion to receive automatic transfers of your winnings directly to your wallet.",
      id: "Step-4",
      card: StepFour,
    },
  ];
  return (
    <div className="Main flex flex-col items-center justify-center gap-2">
      <motion.div className="Home bg-white min-h-screen relative rounded-[40px] w-full flex flex-col items-center justify-between">
        <Navbar />
        <div className="container h-full flex items-start mt-[150px] justify-top w-fit flex-col p-4 z-10">
          <motion.h1
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              delay: "0.5",
              type: "spring",
            }}
            viewport={{ once: true }}
            className="font-extrabold font-Poppins text-[#202020] text-4xl text-start md:text-6xl  "
          >
            Analyse, Predict &<br /> Earn with
            <span className="text-[#FFD401]"> Bart. </span>{" "}
          </motion.h1>
          <motion.div
            className="buttons  flex h-fit flex-row items-center justify-start mt-4 gap-4"
            initial={{ opacity: 0, translateY: -20 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              delay: "0",
              type: "spring",
            }}
            viewport={{ once: true }}
          >
            <div className="social flex gap-2 flex-row items-center justify-center p-2 bg-[#202020] rounded-full">
              <motion.a
                href=""
                target="_blank"
                className=" fill-[#202020]  rounded-full p-2 bg-white"
                whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                whileTap={{ scaleY: 0.8, scaleX: 0.8 }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3012 0.00181919C18.0122 0.0157725 17.747 0.108028 17.514 0.209953C17.2987 0.304039 16.5258 0.664625 15.2949 1.23761C14.0641 1.8106 12.4332 2.57031 10.6947 3.38182C7.21786 5.00485 3.31076 6.83055 1.30876 7.76564C1.24001 7.79743 0.960802 7.8923 0.652427 8.15372C0.343337 8.41575 0 8.98441 0 9.60632C0 10.108 0.225643 10.619 0.498111 10.9115C0.770579 11.204 1.04672 11.3408 1.27751 11.4427V11.4405C2.05764 11.7855 4.38735 12.8202 4.94204 13.0665C5.13879 13.7209 5.98345 16.5225 6.18439 17.2271H6.18243C6.32446 17.7257 6.4629 18.0507 6.65515 18.3219C6.75127 18.4576 6.86601 18.5805 7.00676 18.6775C7.06076 18.7147 7.12055 18.7436 7.18061 18.7707C7.18898 18.7747 7.1976 18.7756 7.206 18.7794L7.18256 18.7729C7.19997 18.7806 7.2156 18.7921 7.23335 18.7989C7.26227 18.8099 7.28231 18.8096 7.32125 18.8184C7.45827 18.8652 7.59505 18.8965 7.72169 18.8965C8.26537 18.8965 8.59876 18.5691 8.59876 18.5691L8.62024 18.5517L10.9682 16.3468L13.8436 19.2997C13.8961 19.382 14.3075 20 15.2598 20C15.828 20 16.2772 19.6878 16.5646 19.3604C16.852 19.033 17.0311 18.698 17.1116 18.2439L17.1135 18.2417C17.1777 17.8752 19.9303 2.53411 19.9303 2.53411L19.9244 2.56013C20.0101 2.13571 20.0354 1.72526 19.9342 1.30699C19.833 0.888718 19.5599 0.480527 19.231 0.266322C18.9021 0.0521181 18.5902 -0.0121345 18.3012 0.00181919ZM17.9066 2.43221C17.7978 3.03833 15.3321 16.7874 15.1797 17.6498L11.0268 13.3853L8.2198 16.0194L8.99725 12.6459C8.99725 12.6459 14.3605 6.62045 14.6835 6.26968C14.9436 5.98884 14.998 5.89039 14.998 5.79271C14.998 5.66283 14.9378 5.5694 14.7988 5.5694C14.6738 5.5694 14.504 5.70236 14.414 5.76452C13.2702 6.55599 8.39845 9.63737 6.00272 11.15C5.85855 11.0858 3.69396 10.1211 2.52767 9.60415C4.6022 8.63509 8.1583 6.9745 11.4702 5.42847C13.2084 4.6171 14.8388 3.8567 16.0685 3.28426C17.1064 2.80105 17.6479 2.55085 17.9066 2.43221ZM15.1504 17.8081H15.1523L15.1504 17.8146C15.1516 17.8074 15.1494 17.8137 15.1504 17.8081Z"
                    fill="#202020"
                  />
                </svg>
              </motion.a>
              <motion.a
                href=""
                target="_blank"
                className=" fill-[#202020] rounded-full p-2 bg-white"
                whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
                whileTap={{ scaleY: 0.8, scaleX: 0.8 }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.147569 0L7.77995 10.9093L0 20H2.94054L9.09505 12.7886L14.1406 20H20.7075L12.7105 8.55252L20.0108 0H17.1159L11.4019 6.67752L6.73828 0H0.147569Z"
                    fill="#202020"
                  />
                </svg>
              </motion.a>
            </div>
            <motion.a
              href=""
              target="_blank"
              className="flex gap-2 text-white font-medium font-Poppins text-xl  flex-row items-center justify-center px-6 py-3 bg-[#202020] fill-white rounded-full"
              whileHover={{
                background: "white",
                color: "#202020",
                fill: "#202020",
              }}
              whileTap={{ scaleY: 0.9, scaleX: 0.9 }}
            >
              White Paper{" "}
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.76 11.83V3.75C16.76 1.67893 15.0811 0 13.01 0H4.25C2.17893 0 0.5 1.67893 0.5 3.75V14.29C0.5 16.0628 1.93717 17.5 3.71 17.5H16.01C16.4242 17.5 16.76 17.1642 16.76 16.75C16.76 16.3358 16.4242 16 16.01 16H3.71C2.76559 16 2 15.2344 2 14.29C2 13.3456 2.76559 12.58 3.71 12.58H16.01C16.4242 12.58 16.76 12.2442 16.76 11.83ZM2 11.58V3.75C2 2.50736 3.00736 1.5 4.25 1.5H13.01C14.2526 1.5 15.26 2.50736 15.26 3.75V11.08H3.71C3.10416 11.0814 2.51118 11.2548 2 11.58Z"
                />
                <path d="M2.96 14.29C2.96 13.8758 3.29578 13.54 3.71 13.54H14.78C15.1942 13.54 15.53 13.8758 15.53 14.29C15.53 14.7042 15.1942 15.04 14.78 15.04H3.71C3.29578 15.04 2.96 14.7042 2.96 14.29Z" />
              </svg>
            </motion.a>
          </motion.div>
        </div>

        {/* <motion.video
          initial={{ opacity: 0, translateY: 100 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ delay: 0.8 }}
          viewport={{ once: true }}
          src={HeroWebm}
          className="h-[80vh] bottom-0 w-full object-cover rounded-b-[40px] z-0 "
          autoPlay
          controls={false}
          loop
          muted
        ></motion.video> */}
        <motion.img
          initial={{ opacity: 0, translateY: 100 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          transition={{ delay: 0.8 }}
          viewport={{ once: true }}
          src={heroPNG}
          className="md:h-[max] bottom-0 md:w-fit w-[80%] object-contain z-0 m-10"
        />
      </motion.div>
      <motion.div className="About bg-white/15 border-2 border-white/25  relative p-4 rounded-[40px] w-full flex items-center justify-center py-36">
        <motion.div className="container flex items-center justify-center  gap-12 flex-col-reverse md:flex-row  font-light font-Roboto ">
          <motion.img
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            src={aboutPNG}
            alt=""
            className="md:w-2/3"
          />
          <div className="flex items-center justify-center flex-col gap-12 md:my-10">
            <motion.p
              initial={{ opacity: 0.2 }}
              viewport={{ amount: 1, margin: "-250px 0px -250px 0px" }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.2, ease: "linear" }}
              className="text-[#FFD401] md:text-4xl text-2xl  "
            >
              Hey, party people! Bart in the virtual flesh and I've got the lowdown on the coolest app in the crypto town Bart.fund!
            </motion.p>
            <motion.p
              initial={{ opacity: 0.2 }}
              viewport={{ amount: 1, margin: "-250px 0px -250px 0px" }}
              transition={{ duration: 0.2, ease: "linear" }}
              whileInView={{ opacity: 1 }}
              className="text-[#FFD401] md:text-4xl text-2xl  "
            >
              It's like having your very own crypto playground, where opinions are the currency, and mischief is the language. Join the Bart.fund revolution.
            </motion.p>
            <motion.p
              initial={{ opacity: 0.2 }}
              viewport={{ amount: 1, margin: "-250px 0px -250px 0px" }}
              transition={{ duration: 0.2, ease: "linear" }}
              whileInView={{ opacity: 1 }}
              className="text-[#FFD401] md:text-4xl text-2xl  "
            >
              But hold your horses, or in my case, my skateboard! Bart.fund isn't just about the Benjamins; it's about building a community of opinionated trailblazers.
            </motion.p>
            <motion.p
              initial={{ opacity: 0.2 }}
              viewport={{ amount: 1, margin: "-250px 0px -250px 0px" }}
              transition={{ duration: 0.2, ease: "linear" }}
              whileInView={{ opacity: 1 }}
              className="text-[#FFD401] md:text-4xl text-2xl "
            >
              But wait, there's more! Regular updates will keep the party alive, introducing fresh challenges, new trading options, and perhaps a few surprises only a Simpson could cook up
            </motion.p>
          </div>
        </motion.div>
      </motion.div>

      <div className="flex  container items-start gap-4">
        <div className="w-full py-[50vh]">
          <ul>
            {features.map((feature) => (
              <li key={feature.id}>
                <Title id={feature.id} heading={feature.heading}>
                  {feature.title}
                </Title>
              </li>
            ))}
          </ul>
        </div>
        <div className="sticky right-0 top-1/2 transform -translate-y-1/2 flex h-full w-full items-center">
          <div className="relative aspect-square w-full rounded-2xl">
            {features.map((feature) => (
              <feature.card id={feature.id} key={feature.id} />
            ))}
          </div>
        </div>
      </div>

      <motion.div className="Features bg-white/15  relative p-4 rounded-[40px] w-full flex items-start justify-center ">
        <div className="container flex flex-col items-center justify-start my-10 md:px-40 ">
          <motion.h1
            className="font-bold font-rubik text-6xl md:text-8xl text-white text-center my-10 md:my-20 "
            initial={{ opacity: 0, translateY: -50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              delay: 0.5,
            }}
            viewport={{ once: true }}
          >
            <motion.span
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                delay: 1,
              }}
              viewport={{ once: true }}
              className="bg-clip-text text-transparent bg-gradient-to-r from-white to-transparent"
            >
              Unique
            </motion.span>{" "}
            Feaures.
          </motion.h1>
          <div className="items flex flex-col md:flex-row items-center justify-center gap-10 text-white font-Roboto text-xl my-10 md:my-20 overflow-hidden backdrop-blur-2xl bg-black/15 p-8 rounded-[40px]">
            <motion.div
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ delay: 0.2, ease: "easeInOut" }}
              viewport={{ once: true }}
              className="card flex flex-col items-start justify-start h-fit gap-4"
            >
              <div className="icons flex flex-row items-start justify-center gap-4">
                <h1 className="md:text-2xl text-xl font-Rubik font-bold">
                  Predictive Trading Interface
                </h1>
                <svg
                  className="md:h-10 md:w-10 h-8 w-8"
                  viewBox="0 0 51 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.0625 18.8736H42.1667C45.6185 18.8736 48.4167 21.6718 48.4167 25.1236V37.6236C48.4167 41.0754 45.6185 43.8736 42.1667 43.8736H8.83334C5.38156 43.8736 2.58334 41.0754 2.58334 37.6236V25.1236C2.58334 21.6718 5.38156 18.8736 8.83334 18.8736H23.9375V14.707C23.9375 12.6934 25.5698 11.0611 27.5833 11.0611C27.871 11.0611 28.1042 10.8279 28.1042 10.5403C28.1042 9.67734 28.8037 8.97778 29.6667 8.97778C30.5296 8.97778 31.2292 9.67734 31.2292 10.5403C31.1952 12.5328 29.5761 14.1334 27.5833 14.1445C27.4377 14.144 27.2985 14.2045 27.1995 14.3114C27.1005 14.4183 27.0509 14.5618 27.0625 14.707V18.8736ZM42.1667 40.7486C43.8926 40.7486 45.2917 39.3495 45.2917 37.6236V25.1236C45.2576 23.4222 43.8685 22.0608 42.1667 22.0611H8.83334C7.13157 22.0608 5.74238 23.4222 5.70834 25.1236V37.6236C5.70834 39.3495 7.10745 40.7486 8.83334 40.7486H42.1667Z"
                    fill="white"
                  />
                  <path
                    d="M19.25 29.8528H16.6458V27.207C16.6458 26.344 15.9463 25.6445 15.0833 25.6445C14.2204 25.6445 13.5208 26.344 13.5208 27.207V29.8111H10.9167C10.0537 29.8111 9.35418 30.5107 9.35418 31.3736C9.35418 32.2366 10.0537 32.9361 10.9167 32.9361H13.5208V35.5403C13.5208 36.4032 14.2204 37.1028 15.0833 37.1028C15.9463 37.1028 16.6458 36.4032 16.6458 35.5403V32.9778H19.25C20.113 32.9778 20.8125 32.2782 20.8125 31.4153C20.8125 30.5523 20.113 29.8528 19.25 29.8528Z"
                    fill="white"
                  />
                  <path
                    d="M35.9167 27.2486C35.9167 28.3992 34.9839 29.332 33.8333 29.332C32.6828 29.332 31.75 28.3992 31.75 27.2486C31.75 26.098 32.6828 25.1653 33.8333 25.1653C34.9839 25.1653 35.9167 26.098 35.9167 27.2486Z"
                    fill="white"
                  />
                  <path
                    d="M35.9167 35.582C35.9167 36.7325 34.9839 37.6653 33.8333 37.6653C32.6828 37.6653 31.75 36.7325 31.75 35.582C31.75 34.4314 32.6828 33.4986 33.8333 33.4986C34.9839 33.4986 35.9167 34.4314 35.9167 35.582Z"
                    fill="white"
                  />
                  <path
                    d="M40.0833 31.4153C40.0833 32.5659 39.1506 33.4986 38 33.4986C36.8494 33.4986 35.9167 32.5659 35.9167 31.4153C35.9167 30.2647 36.8494 29.332 38 29.332C39.1506 29.332 40.0833 30.2647 40.0833 31.4153Z"
                    fill="white"
                  />
                  <path
                    d="M31.75 31.4153C31.75 32.5659 30.8173 33.4986 29.6667 33.4986C28.5161 33.4986 27.5833 32.5659 27.5833 31.4153C27.5833 30.2647 28.5161 29.332 29.6667 29.332C30.8173 29.332 31.75 30.2647 31.75 31.4153Z"
                    fill="white"
                  />
                </svg>
              </div>
              <h1 className="font-inter text-base md:text-lg text-white/75">
                Our platform offers an intuitive and user-friendly interface for predictive trading. Users can make informed predictions on various market trends and seamlessly execute trades based on their insights. The platform employs advanced algorithms and real-time market data to empower users in making well-informed decisions.
              </h1>
            </motion.div>
            <div className="line w-56 md:w-1 h-1 md:h-56 bg-white"></div>
            <motion.div
              initial={{ opacity: 0, translateY: 100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ delay: 0.4, ease: "easeInOut" }}
              viewport={{ once: true }}
              className="card flex flex-col items-start justify-start h-fit gap-4"
            >
              <div className="icons flex flex-row items-start justify-center gap-4 ">
                <h1 className="md:text-2xl text-xl font-Rubik font-bold">
                  Decentralized Prediction Pools
                </h1>
                <svg
                  className="md:h-10 md:w-10 h-8 w-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.65 7.59C8.35449 7.31464 7.89399 7.32277 7.60838 7.60838C7.32277 7.89399 7.31464 8.35449 7.59 8.65L11.37 12.43V17.12C11.37 17.5342 11.7058 17.87 12.12 17.87C12.5342 17.87 12.87 17.5342 12.87 17.12V12.12C12.8698 11.9212 12.7907 11.7305 12.65 11.59L8.65 7.59Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2H14.24C16.3617 2 18.3966 2.84285 19.8969 4.34315C21.3971 5.84344 22.24 7.87827 22.24 10V14.24C22.24 18.6583 18.6583 22.24 14.24 22.24H10C5.58172 22.24 2 18.6583 2 14.24V10C2 5.58172 5.58172 2 10 2ZM14.24 20.74C17.8276 20.7345 20.7345 17.8276 20.74 14.24V10C20.7345 6.41243 17.8276 3.50551 14.24 3.5H10C6.41243 3.50551 3.50551 6.41243 3.5 10V14.24C3.50551 17.8276 6.41243 20.7345 10 20.74H14.24Z"
                    fill="white"
                  />
                </svg>
              </div>
              <h1 className="font-inter text-base md:text-lg text-white/75">
                Unlike traditional prediction markets, our platform operates on a decentralized model. Users can participate in prediction pools without the need for intermediaries. The decentralized structure ensures transparency, security, and fair rewards distribution. Smart contracts govern the execution of predictions, providing trust and efficiency in the trading process.
              </h1>
            </motion.div>
            <div className="line w-56 md:w-1 h-1 md:h-56 bg-white"></div>
            <motion.div
              initial={{ opacity: 0, translateX: 100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ delay: 0.6, ease: "easeInOut" }}
              viewport={{ once: true }}
              className="card flex flex-col items-start justify-start h-fit gap-4"
            >
              <div className="icons flex flex-row items-start justify-center gap-4 ">
                <h1 className="md:text-2xl text-xl font-Rubik font-bold">
                  Rewarding Accuracy with Native Tokens
                </h1>
                <svg
                  className="md:h-10 md:w-10 h-8 w-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.95 9.11C21.6908 9.10827 22.3718 9.51614 22.72 10.17C23.0816 10.8105 23.0624 11.5978 22.67 12.22L18.1 19.07C17.6249 19.8375 16.6947 20.1916 15.8295 19.9344C14.9643 19.6772 14.3786 18.8724 14.4 17.97V15.12H12.26C11.5441 15.1228 10.8812 14.7428 10.522 14.1235C10.1628 13.5041 10.162 12.74 10.52 12.12L15.16 4C15.5169 3.38183 16.1762 3.00073 16.89 3C17.9946 3 18.89 3.89543 18.89 5V9.11H20.95ZM16.8 18.24L21.37 11.39C21.486 11.2356 21.5028 11.0282 21.413 10.8572C21.3232 10.6862 21.143 10.5822 20.95 10.59H18.39C17.8377 10.59 17.39 10.1423 17.39 9.59V4.98C17.39 4.84564 17.336 4.71692 17.24 4.62286C17.1441 4.5288 17.0143 4.47731 16.88 4.48C16.6969 4.48062 16.5301 4.58535 16.45 4.75L11.81 12.87C11.7251 13.0251 11.7285 13.2135 11.8189 13.3655C11.9092 13.5175 12.0732 13.6105 12.25 13.61H14.89C15.4423 13.61 15.89 14.0577 15.89 14.61V17.96C15.8927 18.1772 16.0353 18.3679 16.2429 18.4318C16.4506 18.4956 16.6757 18.4182 16.8 18.24Z"
                    fill="white"
                  />
                  <path
                    d="M8.75 5.85H3.95C3.53579 5.85 3.2 6.18579 3.2 6.6C3.2 7.01421 3.53579 7.35 3.95 7.35H8.75C9.16421 7.35 9.5 7.01421 9.5 6.6C9.5 6.18579 9.16421 5.85 8.75 5.85Z"
                    fill="white"
                  />
                  <path
                    d="M8.3 11.33C8.29461 10.918 7.96196 10.5854 7.55 10.58H2.75C2.33579 10.58 2 10.9158 2 11.33C2 11.7442 2.33579 12.08 2.75 12.08H7.55C7.74891 12.08 7.93968 12.001 8.08033 11.8603C8.22098 11.7197 8.3 11.5289 8.3 11.33Z"
                    fill="white"
                  />
                  <path
                    d="M8.75 16.35H3.95C3.53579 16.35 3.2 16.6858 3.2 17.1C3.2 17.5142 3.53579 17.85 3.95 17.85H8.75C9.16421 17.85 9.5 17.5142 9.5 17.1C9.5 16.6858 9.16421 16.35 8.75 16.35Z"
                    fill="white"
                  />
                </svg>
              </div>
              <h1 className="font-inter text-base md:text-lg text-white/75">
                To incentivize accurate predictions, our platform rewards users with native tokens for successful trades. These tokens can be used within the platform for various purposes, including accessing premium features, participating in exclusive events, and unlocking additional prediction insights. This unique reward system encourages users to enhance their predictive skills and actively engage in the platform's ecosystem.
              </h1>
            </motion.div>
          </div>
        </div>
      </motion.div>
      <motion.div className="Roadmap  relative md:p-4 rounded-[40px] w-full flex items-start justify-center ">
        <div class="container mx-auto flex flex-col items-start lg:flex-row my-12 md:my-24">
          <div class="flex flex-col w-full lg:sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
            <motion.h1 className="font-bold font-rubik text-6xl md:text-8xl text-white text-start"
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                delay: 0.5,
              }}
              viewport={{ once: true }}>Roadmap</motion.h1>
            <motion.p className="text-xl md:text-2xl font-Inter text-white my-5"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                delay: 0.5,
              }}
              viewport={{ once: true }}>Navigating Our Journey to Success</motion.p>

            <motion.a
              href=""
              target="_blank"
              className="flex gap-2 text-white font-medium font-Poppins text-xl w-fit flex-row items-center justify-center px-6 py-3 bg-[#202020] fill-white rounded-full"
              whileHover={{
                background: "white",
                color: "#202020",
                fill: "#202020",
              }}
              whileTap={{ scaleY: 0.9, scaleX: 0.9 }}
            >
              White Paper{" "}
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.76 11.83V3.75C16.76 1.67893 15.0811 0 13.01 0H4.25C2.17893 0 0.5 1.67893 0.5 3.75V14.29C0.5 16.0628 1.93717 17.5 3.71 17.5H16.01C16.4242 17.5 16.76 17.1642 16.76 16.75C16.76 16.3358 16.4242 16 16.01 16H3.71C2.76559 16 2 15.2344 2 14.29C2 13.3456 2.76559 12.58 3.71 12.58H16.01C16.4242 12.58 16.76 12.2442 16.76 11.83ZM2 11.58V3.75C2 2.50736 3.00736 1.5 4.25 1.5H13.01C14.2526 1.5 15.26 2.50736 15.26 3.75V11.08H3.71C3.10416 11.0814 2.51118 11.2548 2 11.58Z"
                />
                <path d="M2.96 14.29C2.96 13.8758 3.29578 13.54 3.71 13.54H14.78C15.1942 13.54 15.53 13.8758 15.53 14.29C15.53 14.7042 15.1942 15.04 14.78 15.04H3.71C3.29578 15.04 2.96 14.7042 2.96 14.29Z" />
              </svg>
            </motion.a>
          </div>
          <div class="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div class="container  md:w-full h-full mt-8">
              <div class="relative wrap overflow-hidden md:p-10 h-full">
                <div class="border-2-2 border-yellow-555 absolute h-full border"
                  style={{ right: "50%", border: "2px solid #FFC100", borderRadius: "1%", }}></div>
                <div class="border-2-2 border-yellow-555 absolute h-full border"
                  style={{ right: "50%", border: "2px solid #FFC100", borderRadius: "1%", }}></div>
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div class="order-1 w-1/2 md:w-5/12"></div>
                  <div class="order-1 w-1/2 md:w-5/12 px-1 py-4 text-right flex flex-col items-end pr-2">
                    <motion.p
                      initial={{ opacity: 0, translateX: -50 }}
                      whileInView={{ opacity: 1, translateX: 0 }}
                      transition={{
                        duration: 0.5
                      }} class="mb-3 text-2xl font-bold font-Rubik text-[#F7D444]">Q4 2023</motion.p>
                    <motion.div initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.2
                      }} className="bg-[#F7D444] p-8 rounded-2xl flex flex-row items-start w-full md:w-fit ">
                      <ol className="text-black text-start list-decimal font-Rubik font-bold text-sm list-outside md:gap-4 gap-2 flex flex-col">
                        <li>Conceptualization and Planning</li>
                        <li>Research and Market Analysis</li>
                        <li>Initial Prototyping</li>
                        <li>Beta Development</li>
                      </ol>
                    </motion.div>
                  </div>
                </div>
                <div class="mb-8 flex justify-between items-center w-full right-timeline ">
                  <div class="order-1 w-1/2 md:w-5/12"></div>
                  <div class="order-1  w-1/2 md:w-5/12 px-1 py-4 text-left flex flex-col items-start pr-2">
                    <motion.p
                      initial={{ opacity: 0, translateX: 50 }}
                      whileInView={{ opacity: 1, translateX: 0 }}
                      transition={{
                        duration: 0.5
                      }} class="mb-3 text-2xl font-bold font-Rubik text-[#F7D444]">Q1 2024</motion.p>
                    <motion.div initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.5
                      }} className="bg-[#F7D444] p-8 rounded-2xl flex flex-row items-start w-full md:w-fit ">
                      <ol className="text-black text-start list-decimal font-Rubik font-bold text-sm list-outside md:gap-4 gap-2 flex flex-col">
                        <li>Beta Version Launch</li>
                        <li>Alpha Version Testing</li>
                        <li>Full Product Launch</li>
                        <li>Community Engagement Initiatives</li>
                        <li>Seed Funding Campaigns</li>
                      </ol>
                    </motion.div>
                  </div>
                </div>
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline pr-2">
                  <div class="order-1  w-1/2 md:w-5/12"></div>
                  <div class="order-1  w-1/2 md:w-5/12 px-1 py-4 text-right flex flex-col items-end">
                    <motion.p
                      initial={{ opacity: 0, translateX: -50 }}
                      whileInView={{ opacity: 1, translateX: 0 }}
                      transition={{
                        duration: 0.5
                      }} class="mb-3 text-2xl font-bold font-Rubik text-[#F7D444]">Q2 2024</motion.p>
                    <motion.div initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.2
                      }} className="bg-[#F7D444] p-8 rounded-2xl flex flex-row items-start  w-full md:w-fit  ">
                      <ol className="text-black text-start list-decimal font-Rubik font-bold text-sm list-outside md:gap-4 gap-2 flex flex-col">
                        <li>Token generation event</li>
                        <li>User Feedback Integration</li>
                        <li>Security Audits and Enhancements</li>
                        <li>Partnership Explorations</li>
                      </ol>
                    </motion.div>
                  </div>
                </div>

                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                  <div class="order-1 w-1/2 md:w-5/12"></div>

                  <div class="order-1  w-1/2 md:w-5/12 px-1 py-4 text-left flex flex-col items-start">
                    <motion.p
                      initial={{ opacity: 0, translateX: 50 }}
                      whileInView={{ opacity: 1, translateX: 0 }}
                      transition={{
                        duration: 0.5
                      }} class="mb-3 text-2xl font-bold font-Rubik text-[#F7D444]">Q3 2024</motion.p>
                    <motion.div initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.5
                      }} className="bg-[#F7D444] p-8 rounded-2xl flex flex-row items-start  w-full md:w-fit">
                      <ol className="text-black text-start list-decimal font-Rubik font-bold text-sm list-outside md:gap-4 gap-2 flex flex-col">
                        <li>App development</li>
                        <li>Cex Listing</li>
                        <li>Global Marketing Campaigns</li>
                        <li>Community Building and Events</li>
                      </ol>
                    </motion.div>
                  </div>
                </div>
              </div>
              <img class="mx-auto md:w-1/2" src={roadmapImg} />
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div className="Footer bg-[#F7D444]  relative p-4 rounded-t-[40px] w-full flex items-center justify-center -bottom-2">
        <div className="container flex flex-col items-center justify-center py-40 md:px-40 relative">
          <img src={homer} alt="" className="md:w-1/3 absolute top-0 drop-shadow-2xl" />
          <div className="flex flex-col items-start justify-center mb-4">

            <motion.h1 className="font-Poppins font-black text-black text-6xl md:text-9xl">Bart.FUND</motion.h1>
            <motion.p className="font-Rubik font-light text-black text-sm md:text-lg">Bart.FUND © 2024. All rights reserved.</motion.p>
          </div>
          <div className="social flex gap-2 flex-row items-center justify-center p-2 bg-[#202020] rounded-full">
            <motion.a
              href=""
              target="_blank"
              className=" fill-[#202020]  rounded-full p-2 bg-white"
              whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
              whileTap={{ scaleY: 0.8, scaleX: 0.8 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3012 0.00181919C18.0122 0.0157725 17.747 0.108028 17.514 0.209953C17.2987 0.304039 16.5258 0.664625 15.2949 1.23761C14.0641 1.8106 12.4332 2.57031 10.6947 3.38182C7.21786 5.00485 3.31076 6.83055 1.30876 7.76564C1.24001 7.79743 0.960802 7.8923 0.652427 8.15372C0.343337 8.41575 0 8.98441 0 9.60632C0 10.108 0.225643 10.619 0.498111 10.9115C0.770579 11.204 1.04672 11.3408 1.27751 11.4427V11.4405C2.05764 11.7855 4.38735 12.8202 4.94204 13.0665C5.13879 13.7209 5.98345 16.5225 6.18439 17.2271H6.18243C6.32446 17.7257 6.4629 18.0507 6.65515 18.3219C6.75127 18.4576 6.86601 18.5805 7.00676 18.6775C7.06076 18.7147 7.12055 18.7436 7.18061 18.7707C7.18898 18.7747 7.1976 18.7756 7.206 18.7794L7.18256 18.7729C7.19997 18.7806 7.2156 18.7921 7.23335 18.7989C7.26227 18.8099 7.28231 18.8096 7.32125 18.8184C7.45827 18.8652 7.59505 18.8965 7.72169 18.8965C8.26537 18.8965 8.59876 18.5691 8.59876 18.5691L8.62024 18.5517L10.9682 16.3468L13.8436 19.2997C13.8961 19.382 14.3075 20 15.2598 20C15.828 20 16.2772 19.6878 16.5646 19.3604C16.852 19.033 17.0311 18.698 17.1116 18.2439L17.1135 18.2417C17.1777 17.8752 19.9303 2.53411 19.9303 2.53411L19.9244 2.56013C20.0101 2.13571 20.0354 1.72526 19.9342 1.30699C19.833 0.888718 19.5599 0.480527 19.231 0.266322C18.9021 0.0521181 18.5902 -0.0121345 18.3012 0.00181919ZM17.9066 2.43221C17.7978 3.03833 15.3321 16.7874 15.1797 17.6498L11.0268 13.3853L8.2198 16.0194L8.99725 12.6459C8.99725 12.6459 14.3605 6.62045 14.6835 6.26968C14.9436 5.98884 14.998 5.89039 14.998 5.79271C14.998 5.66283 14.9378 5.5694 14.7988 5.5694C14.6738 5.5694 14.504 5.70236 14.414 5.76452C13.2702 6.55599 8.39845 9.63737 6.00272 11.15C5.85855 11.0858 3.69396 10.1211 2.52767 9.60415C4.6022 8.63509 8.1583 6.9745 11.4702 5.42847C13.2084 4.6171 14.8388 3.8567 16.0685 3.28426C17.1064 2.80105 17.6479 2.55085 17.9066 2.43221ZM15.1504 17.8081H15.1523L15.1504 17.8146C15.1516 17.8074 15.1494 17.8137 15.1504 17.8081Z"
                  fill="#202020"
                />
              </svg>
            </motion.a>
            <motion.a
              href=""
              target="_blank"
              className=" fill-[#202020] rounded-full p-2 bg-white"
              whileHover={{ scaleY: 1.1, scaleX: 1.1 }}
              whileTap={{ scaleY: 0.8, scaleX: 0.8 }}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.147569 0L7.77995 10.9093L0 20H2.94054L9.09505 12.7886L14.1406 20H20.7075L12.7105 8.55252L20.0108 0H17.1159L11.4019 6.67752L6.73828 0H0.147569Z"
                  fill="#202020"
                />
              </svg>
            </motion.a>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
